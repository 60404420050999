import React from 'react';
import DefineTable from '../../../../components/DefineTable';
import { Table } from 'antd';
import styled from "styled-components";
import 'antd/dist/antd.css';

import PropTypes from "prop-types";
import icon_edit from "../../../../assets/images/icons/icon_edit.png";
import { useSelector } from 'react-redux';
import { NodeExpandOutlined } from '@ant-design/icons'
import { integerToRoman } from '../../../../helper/helper';



function convertToRoman(num) {
    const romanNumerals = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];
    return romanNumerals[num - 1];
}

const List = ({ className, data, onEdit, dataDepartments }) => {

    const departments = useSelector(state => state.departments)
    // console.log(departments);

    const columns = [
        {
            title: "STT",
            dataIndex: 'id',
            key: 'id',
            width: 80,

            render: (text, record, index) => {

                if (record?.parent_id == null) {
                    return <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{integerToRoman(index + 1)}</div>
                } else if (record?.children?.length > 0) {
                    return <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{index + 1}</div>
                } else {
                    return <div style={{ textAlign: 'center' }}>{index + 1}</div>

                }
                // return record?.parent_id == null ? (
                //     <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{convertToRoman(index + 1)}</div>
                // ) : (
                //     <div style={{ textAlign: 'center' }}>{index + 1}</div>
                // );
            },
        },
        {
            title: "Objective",
            dataIndex: 'name',
            key: 'name',
            width: 300,
            render: (text, record, index) => {
                return text;
            }
        },
        {
            title: "Mô tả",
            dataIndex: 'description',
            key: 'description',
            width: 300,
            render: (text, record, index) => {
                return text;
            }
        },
        {
            title: "Phòng ban thực hiện",
            dataIndex: 'assignee',
            width: 200,
            // render: (text, record, index) => {
            //     return departments?.map(item => {
            //         return item?.id === record?.department_id && item?.name
            //     });
            // }
            render: (text, record, index) => {
                if (record?.department?.phb_parent_id === 0) {
                    return record?.department?.phb_name;
                } else {
                    return (
                        <>
                            {dataDepartments?.map(item => {
                                return (
                                    <>
                                        {item?.id === record?.department?.phb_parent_id &&
                                            <>
                                                <div>{item?.name}</div>
                                                <div style={{ paddingLeft: 20 }}><NodeExpandOutlined />&nbsp;&nbsp;{record?.department?.phb_name}</div>
                                            </>

                                        }

                                    </>);
                                // item?.id === record?.objective?.parent_id && item?.name + " ------> " + record?.objective?.name);
                            })}
                        </>
                    );
                }
            }
        },
        {
            title: "Edit",
            width: 80,
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <img alt="Edit" src={icon_edit} style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => onEdit(record)} />
                    </div>
                )
            },
        }
    ];


    const expandIcon = () => {
        return null;
    };
    // console.log(data);

    return (
        <div className={className}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                defaultExpandAllRows={true}
                size="small"
                rowKey={'id'}
                scroll={{ x: "100vh", y: "calc(100vh - 150px)" }}
                expandable={{
                    expandRowByClick: false,
                    expandIcon,
                    // expandedRowRender,
                    expandIconColumnIndex: -1
                }}
                bordered
            />

        </div>
    );
};

List.propTypes = {
    className: PropTypes.any,
};
export default styled(List)`
.custom-table-container {
}
.ant-table-thead > tr > th {
  background-color: #1F78B4;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
}

.ant-table-tbody > tr.ant-table-row-level-0 > td {
  background-color: #E9ECEE;
  font-weight:500;
  font-size: 15px;
}

.ant-table-tbody > tr.ant-table-row-level-1 > td {
  background-color: #ffff;
  font-weight:450;
  font-size: 14px;
  padding-left:15px;
}
`;