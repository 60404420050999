import { Col, DatePicker, Form, Input, Modal, Row, Select, Spin, message, TreeSelect, InputNumber } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createKeyResult, editKeyResult, getKeyResult } from '../../../api/okr';
import moment from 'moment';
import { setKeyResult } from '../../../stores/actions/ork/keyResult';
import { formatNumber } from '../../../helper/helper';

const { Option } = Select;
const { TreeNode } = TreeSelect;

const ModalCreateKeyResult = ({ visible, setVisible, itemSelected, setItemSelected }) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const [search, setSearch] = useState("");
    const [is_staff, setIsStaff] = useState(itemSelected?.employee === 1 ? true : false);
    const [is_manager, setIsManage] = useState(itemSelected?.manager === 1 ? true : false);
    const [valueUnit, setValueUnit] = useState(itemSelected?.unit?.value);

    const [money, setMoney] = useState(null);

    const localSearchFunc = (input, option) => {
        console.log(option);
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    }

    const _handleSearch = useCallback((input) => {
        setFetching(true)
        setTimeout(() => {
            setSearch(input || "");
        }, 666000)
        setFetching(false)
    }, []);

    const [params, setParams] = useState({
        page: 1,
        size: 20
    })

    const [dataKeyResult, setDataKeyResult] = useState([])
    const departments = useSelector(state => state.departments);
    const [dataDepartments, setDataDepartments] = useState(departments);
    const objective = useSelector(state => state.objective);
    const [dataObjective, setDataObjective] = useState(objective);

    const getDataKeyResult = useCallback(async () => {
        getKeyResult(params).then(res => {
            setDataKeyResult(res?.data?.data);
            dispatch(setKeyResult(res?.data?.data))
        });
    }, [dispatch]);



    // useEffect(() => {
    //     getDataKeyResult();
    // }, []);

    useEffect(() => {
        setValueUnit(valueUnit);
    }, [valueUnit])


    // const [dataObjective, setDataObjective] = useState([]);
    // const getDataObjective = useCallback(async () => {
    //     getObjective().then((response) => {
    //         setDataObjective(response.data.data);
    //         dispatch(setObjective(response.data.data));
    //     });
    // }, [dispatch]);

    // const [dataDepartments, setDataDepartments] = useState([]);
    // const getDataDepartments = useCallback(async () => {
    //     listDepartment().then((response) => {
    //         setDataDepartments(response.data.data);
    //         dispatch(setDepartments(response.data.data));
    //     });
    // }, [dispatch]);

    // console.log(dataDepartments);

    // useEffect(() => {
    //     getDataDepartments();
    // }, [])


    const onRefreshList = () => {
        getDataKeyResult();
    }

    useEffect(() => {

        itemSelected === null && getDataKeyResult()
    }, [getDataKeyResult]);


    // useEffect(() => {
    //     getDataObjective()
    // }, [getDataObjective]);

    const [form] = Form.useForm();
    const handleOk = () => {
        form.submit();
    };
    const handleCancel = () => {
        form.resetFields()
        setVisible(false);
        // eslint-disable-next-line no-unused-expressions
        itemSelected ? setItemSelected(null) : null
        // onRefreshList()
    };

    const handleCreateKeyResult = async (values) => {
        // console.log(values);
        const params = {
            id: itemSelected?.id,
            code: values?.code,
            name: values?.name,
            description: values?.description,
            objective_id: values?.objective_id || null,
            due_date: values?.due_date ? moment(values?.due_date).format('YYYY-MM-DD') : null,
            department_id: values?.department_id || null,
            unit: values?.unit || null,
            target: values?.target || ''
        }
        // console.log(params);
        itemSelected ? editKeyResult(params).then((response) => {
            // console.log(response);
            setDataKeyResult(response.data.data);
            onRefreshList()
            setItemSelected(null)
            message.success('Cập nhật Key result thành công!');
        }).catch(err => {
            // console.log(err?.response?.data?.message);
            message.error(err?.response?.data?.message)
        }) :
            createKeyResult(params).then((response) => {
                // console.log(response);
                setDataKeyResult(response.data.data);
                onRefreshList()
                // setItemSelected(null)
                message.success('Tạo mới Key result thành công!');
            }).catch(err => {
                params?.target !== null && message.error(err?.response?.data?.message)
            })
            ;
    }

    const onFinish = (values) => {
        form.resetFields()
        setVisible(false);
        handleCreateKeyResult(values)
    };

    const onChangeStaff = (e) => {
        setIsStaff(e.target.checked)

    };

    const onChangeManager = (e) => {
        setIsManage(e.target.checked)
    };

    // console.log(dataDepartments);

    useEffect(() => {
        setMoney(money);
    }, [money]);

    // console.log(itemSelected);

    const renderTreeDepartments = (data) =>
        data?.map((item) => {
            if (item.children && item.children.length) {
                return (
                    <TreeNode title={item.name} key={item.id} value={item.id} >
                        {renderTreeDepartments(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.name} key={item.id} value={item.id} />;
        });
    const renderTreeObjectives = (data) =>
        data?.map((item) => {
            if (item.children && item.children.length) {
                return (
                    <TreeNode disabled title={item.name} key={item.id} value={item.id} >
                        {renderTreeObjectives(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.name} key={item.id} value={item.id} />;
        });

    return (
        <Modal
            title={itemSelected ? "Cập nhật Key Result" : "Tạo mới Key Result"}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'40%'}
            cancelText="Huỷ"
            okText={itemSelected ? "Cập nhật" : "Tạo mới"}
            style={{ top: '10%' }}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                onFinish={onFinish}
                initialValues={{
                    code: itemSelected && itemSelected.code || '',
                    name: itemSelected && itemSelected.name || '',
                    description: itemSelected && itemSelected.description || '',
                    objective_id: itemSelected && itemSelected.objective_id || null,
                    due_date: itemSelected?.due_date !== '' && moment(itemSelected?.due_date) || '',
                    department_id: itemSelected && itemSelected.department_id || null,
                    unit: itemSelected && itemSelected.unit?.value || null,
                    target: itemSelected && itemSelected.target || ''
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={12}>
                        <Form.Item
                            name="code"
                            label="Mã Key result"
                            rules={[
                                {
                                    required: true,
                                    message: "Mã Key result không được bỏ trống",
                                }
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="Ví dụ: TECH 2021" />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name="name"
                            label="Tên Key result"
                            rules={[
                                {
                                    required: true,
                                    message: "Tên Key result không được bỏ trống",
                                }
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="Ví dụ: TECH 2021" />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name="description"
                            label="Mô tả"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: "Mô tả không được bỏ trống",
                        //     }
                        // ]}
                        // hasFeedback
                        >
                            <Input placeholder="Ví dụ: TECH 2021" />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name={'objective_id'}
                            label={'Chọn Objective'}
                        >
                            {/* <Select
                                allowClear={true}
                                // disabled={itemSelected && itemSelected?.children?.length !== 0 ? true : false}
                                // placeholder={"Tiêu chí"}
                                showSearch
                                style={{ width: '100%' }}
                                filterOption={localSearchFunc}
                                notFoundContent={fetching ? <Spin size="small" /> : "Không có dữ liệu"}
                                onSearch={_handleSearch}
                                onChange={(item) => {
                                }}
                                placeholder="Chọn Objective">
                                {itemSelected ?
                                    _.map(dataObjective?.filter(item => item?.id !== itemSelected?.id), (item) => {
                                        return (
                                            // <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                            <Select.OptGroup key={item?.id} label={item?.name}>
                                                {item?.children?.map(item2 => {
                                                    return (
                                                        <Option key={item2?.id} value={item2?.id}>{item2?.name}</Option>
                                                    );
                                                })}
                                            </Select.OptGroup>
                                        )
                                    })
                                    :
                                    _.map(dataObjective, (item) => {
                                        return (
                                            // <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                            <Select.OptGroup key={item?.id} label={item?.name}>
                                                {item?.children?.map(item2 => {
                                                    return (
                                                        <Option key={item2?.id} value={item2?.id}>{item2?.name}</Option>
                                                    );
                                                })}
                                            </Select.OptGroup>
                                        )
                                    })
                                }
                            </Select> */}
                            <TreeSelect
                                showSearch
                                style={{ width: "100%" }}
                                // value={params?.department}
                                placeholder="Chọn Objective"
                                allowClear
                                treeDefaultExpandAll
                                // onChange={(value) => {
                                //     setParams((props) => {
                                //         let nextState = { ...props };
                                //         nextState["department"] = value;
                                //         return nextState;
                                //     });
                                // }}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    minWidth: 300
                                }}
                                filterTreeNode={(inputValue, treeNode) =>
                                    treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                }
                            >
                                {renderTreeObjectives(objective)}
                            </TreeSelect>
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name={'due_date'}
                            label={"Thời gian hoàn thành"}
                        >
                            <DatePicker
                                placeholder='Nhập thời gian'
                                style={{ width: '100%' }}
                                format={'DD/MM/YYYY'}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name={'department_id'}
                            label={'Phòng ban'}
                        >
                            <TreeSelect
                                showSearch
                                style={{ width: "100%" }}
                                // value={params?.department}
                                placeholder="Chọn phòng ban"
                                allowClear
                                treeDefaultExpandAll
                                // onChange={(value) => {
                                //     setParams((props) => {
                                //         let nextState = { ...props };
                                //         nextState["department"] = value;
                                //         return nextState;
                                //     });
                                // }}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    minWidth: 300
                                }}
                                filterTreeNode={(inputValue, treeNode) =>
                                    treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                }
                            >
                                {renderTreeDepartments(dataDepartments)}
                            </TreeSelect>
                            {/* <Select
                                allowClear={true}
                                // disabled={itemSelected && itemSelected?.children?.length !== 0 ? true : false}
                                // placeholder={"Tiêu chí"}
                                showSearch
                                style={{ width: '100%' }}
                                filterOption={localSearchFunc}
                                notFoundContent={fetching ? <Spin size="small" /> : "Không có dữ liệu"}
                                onSearch={_handleSearch}
                                onChange={(item) => {
                                }}
                                placeholder="Chọn phòng ban"
                            >
                                {_.map(dataDepartments, (item) => {
                                    return (<Option key={item?.id} value={item?.id}>{item?.name}</Option>)
                                })}
                            </Select> */}
                            {/* {renderTreeNodes(dataDepartments)} */}
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name={'unit'}
                            label={'Đơn vị tính'}
                        >
                            <Select onChange={e => setValueUnit(e)} placeholder="Chọn đơn vị tính">
                                <Option value={1}>Số nguyên</Option>
                                <Option value={2}>Phần trăm</Option>
                                <Option value={3}>Chữ</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name="target"
                            label="Mục tiêu"

                        >
                            {valueUnit === 1 ?
                                <InputNumber
                                    placeholder='Mục tiêu'
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ width: '100%', color: '#000' }}

                                /> :
                                <Input placeholder="Mục tiêu" />

                            }
                        </Form.Item>

                    </Col>
                </Row>

            </Form>
        </Modal >
    );
};

export default ModalCreateKeyResult;